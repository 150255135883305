import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";
export default class extends Controller {
    static targets = ["storageSpaceChart", "usersConnectChart", "browserChart", "deviceChart"]

    // Save references to chart instances
    charts = {
        storageSpaceChart: null,
        usersConnectChart: null,
        browserChart: null,
        deviceChart: null
    }

    // Cleanup controller disconnect
    disconnect() {
        // Destroy charts instances
        Object.values(this.charts).forEach(chart => {
            if (chart) {
                chart.destroy();
            }
        });
        // Reset charts object
        this.charts = {
            storageSpaceChart: null,
            usersConnectChart: null,
            browserChart: null,
            deviceChart: null
        };
    }

    connect() {
        let percentage = parseFloat(this.storageSpaceChartTarget.dataset.percentageStorageSpace);
        let usedDevices = parseFloat(this.deviceChartTarget.dataset.usedDevices);
        let maxDevices = parseFloat(this.deviceChartTarget.dataset.maxDevices);
        let usedBrowsers = parseFloat(this.browserChartTarget.dataset.usedBrowsers);
        let maxBrowsers = parseFloat(this.browserChartTarget.dataset.maxBrowsers);
        let usedLicences = parseFloat(this.usersConnectChartTarget.dataset.usedLicences);
        let maxLicences = parseFloat(this.usersConnectChartTarget.dataset.maxLicences);

        this.drawChart("storageSpaceChart", percentage, 'percentage');
        this.drawChart("usersConnectChart", null, 'ratio', usedLicences, maxLicences);
        this.drawChart("browserChart", null, 'ratio', usedBrowsers, maxBrowsers);
        this.drawChart("deviceChart", null, 'ratio', usedDevices, maxDevices);

        // Add resize listener
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    // Handle window resize
    handleResize() {
        // Redraw all charts with updated height
        Object.keys(this.charts).forEach(chartKey => {
            const chart = this.charts[chartKey];
            if (chart) {
                chart.updateOptions({
                    chart: {
                        height: window.innerWidth > 1024 ? 220 : 200
                    }
                });
            }
        });
    }

    drawChart(target, value, type, used = 0, total = 0) {
        // Destroy existing chart if it exists
        if (this.charts[target]) {
            this.charts[target].destroy();
            this.charts[target] = null;
        }

        let percentage;
        let color = '';
        let valueChart = '';
        let emptySpaceColor = '#434343';

        if (type === 'percentage') {
            percentage = value > 0 ? Math.max(1, Math.min(100, Math.floor(value))) : 0;
            valueChart = `${percentage}%`;
        }
        else if (type === 'ratio') {
            percentage = (used / total) * 100;
            percentage = percentage > 100 ? 100 : percentage;
            valueChart = `${used}/${total}`
        }

        let chartLabel = 'Used';
        // Set the label based on the target
        switch (target) {
            case 'browserChart':
                chartLabel = 'Browser';
                break;
            case 'deviceChart':
                chartLabel = 'Device';
                break;
        }

        if (percentage > 0 && percentage <= 75) {
            color = '#12B76A';
        } else if (percentage >= 76 && percentage <= 90) {
            color = '#F1AB24';
        } else if (percentage >= 91) {
            color = '#D22F26';
        }

        const options = {
            chart: {
                type: 'radialBar',
                height: window.innerWidth > 1024 ? 220 : 200,
            },
            series: [percentage],
            labels: [chartLabel],
            colors: [color],
            plotOptions: {
                radialBar: {
                    track: {
                        background: emptySpaceColor, // Set the color of the empty space
                        strokeWidth: '100%',
                    },
                    hollow: {
                        size: '65%',
                    },
                    dataLabels: {
                        name: {
                            fontSize: '14px',
                            offsetY: 25,
                            fontFamily: "Source Sans Pro",
                            color: '#fff',
                            fontWeight: 400,
                            lineHeight: '160%',
                            letterSpacing: '0.014px'
                        },
                        value: {
                            fontSize: '24px',
                            offsetY: -20,
                            fontFamily: "Source Sans Pro",
                            color: '#fff',
                            fontWeight: 700,
                            lineHeight: '160%', // Set line-height
                            letterSpacing: '0.024px',
                            formatter: function () {
                                return valueChart;
                            }
                        }
                    }
                }
            },
            states: {
                normal: {
                    filter: {
                        type: 'none', // Disable any effect in normal state
                    }
                },
                hover: {
                    filter: {
                        type: 'none', // Disable any effect on hover
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none', // Disable any effect on click
                    }
                }
            }
        };

        let chartTarget;
        switch (target) {
            case 'storageSpaceChart':
                chartTarget = this.storageSpaceChartTarget;
                break;
            case 'usersConnectChart':
                chartTarget = this.usersConnectChartTarget;
                break;
            case 'browserChart':
                chartTarget = this.browserChartTarget;
                break;
            case 'deviceChart':
                chartTarget = this.deviceChartTarget;
                break;
            default:
                console.error('Invalid chart target');
                return;
        }

        // Create new chart and store reference
        this.charts[target] = new ApexCharts(chartTarget, options);
        this.charts[target].render();
    }
}