import { Controller } from "@hotwired/stimulus";

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

export default class extends Controller {

    static targets = [
        "deviceCount",
        "storageSize",
        "decreaseDevice",
        "decreaseSize",
        "totalPriceDevice",
        "totalPriceStorage",
        "storageQuantity",
        "loginQuantity",
        "proratedPriceDevice",
        "proratedPriceStorage",
        "devicePriceId",
        "storagePriceId",
        "storageLoader",
        "deviceLoader"
    ];

    connect() {
        // Disable the decrease button when the controller is first connected
        this.decreaseDeviceTarget.disabled = true;
        this.decreaseSizeTarget.disabled = true;

        const currency =  document.querySelector('.f_currency').value;
        this.currency = currency

        // Initialize the debounced version of fetchProratedPrice
        this.debouncedFetchProratedPrice = debounce(this.fetchProratedPrice.bind(this), 300);
    }

    static values = {
        additionalDevice: String,
        additionalStorage: String,
        minStorage: String
    }

    get additionalDeviceValueNumber() {
        return Number(this.additionalDeviceValue.slice(1));
    }

    get additionalStorageValueNumber() {
        return Number(this.additionalStorageValue.slice(1));
    }

    get minStorageValueNumber() {
        return Number(this.minStorageValue.slice(0, -3));
    }

    // General method to increase or decrease the count
    updateValue(type, change) {
        event.preventDefault();

        if (type === "device") {
            let currentCount = parseInt(this.deviceCountTarget.textContent) || 0;
            let newCount = currentCount + change;

            // Update device count display
            const oneDevice = this.deviceCountTarget.dataset.oneDevice;
            const multipleDevices = this.deviceCountTarget.dataset.multipleDevices;
            this.deviceCountTarget.textContent = newCount > 1 ?
                multipleDevices.replace('%{count}', newCount) :
                oneDevice;

            // Enable/Disable decrease button based on device count
            this.decreaseDeviceTarget.classList.toggle("text-graylight", newCount <= 1);
            this.decreaseDeviceTarget.classList.toggle("bg-bghover", newCount > 1);
            this.updateDeviceQuantity(newCount)
        } else if (type === "storage") {
            let currentSize = parseInt(this.storageSizeTarget.textContent) || 0;
            let newSize = currentSize + change;

            // Update storage size display
            this.storageSizeTarget.textContent = `${newSize} GB`;

            // Enable/Disable decrease button based on storage size
            this.decreaseSizeTarget.classList.toggle("text-graylight", newSize <= this.minStorageValueNumber);
            this.decreaseSizeTarget.classList.toggle("bg-bghover", newSize > this.minStorageValueNumber);
            this.updateStorageQuantity(newSize)
        }
    }

    updateStorageQuantity(newSize) {
        const storageQuantity = Math.floor(newSize / this.minStorageValueNumber); 
        this.storageQuantityTarget.value = storageQuantity;
    }

    updateDeviceQuantity(newCount) {
        if (this.hasLoginQuantityTarget) {
            this.loginQuantityTarget.value = newCount;
        } 
    }

    increaseDevice() {
        this.updateValue("device", 1);
        // Update total price after each change
        this.updateTotalPrice("device");
    }

    decreaseDevice() {
        this.updateValue("device", -1);
        // Update total price after each change
        this.updateTotalPrice("device");
    }

    increaseStorage() {
        this.updateValue("storage", this.minStorageValueNumber);
        this.updateTotalPrice("storage");
    }

    decreaseStorage() {
        this.updateValue("storage", -this.minStorageValueNumber);
        this.updateTotalPrice("storage");
    }

    updateTotalPrice(type) {
        let totalPriceDevice = parseInt(this.totalPriceDeviceTarget?.textContent.slice(1)) || 0;
        let totalPriceStorage = parseInt(this.totalPriceStorageTarget?.textContent.slice(1)) || 0;

        const deviceCount = parseInt(this.deviceCountTarget.textContent) || 0;
        const storageSize = parseInt(this.storageSizeTarget.textContent) || 0;

        // Disable the decrease buttons based on minimum values
        this.decreaseDeviceTarget.disabled = (deviceCount == 1);
        this.decreaseSizeTarget.disabled = (storageSize == this.minStorageValueNumber);

        // Calculate total based on device and storage counts
        switch (type) {
            case "device":
                totalPriceDevice = this.additionalDeviceValueNumber * deviceCount;
                break;
            case "storage":
                totalPriceStorage = this.additionalStorageValueNumber * (storageSize / this.minStorageValueNumber);
                break;
        }

        // Update the total price display if targets are available
        if (this.totalPriceDeviceTarget) {
            this.totalPriceDeviceTarget.textContent = `${this.currency}${totalPriceDevice}`;
        }
        if (this.totalPriceStorageTarget) {
            this.totalPriceStorageTarget.textContent = `${this.currency}${totalPriceStorage}`;
        }
        this.debouncedFetchProratedPrice(type);
    }

    handleAddon(event) {
        const type = event.currentTarget.dataset.addonType;
        this.debouncedFetchProratedPrice(type);
    }
    
    fetchProratedPrice(type) {
        const deviceCount = parseInt(this.deviceCountTarget?.textContent) || 1;
        const storageSize = parseInt(this.storageSizeTarget?.textContent) || 512;
        const storageQuantity = Math.floor(storageSize / this.minStorageValueNumber) || 1;
    
        let priceId;
        let loaderTargets, priceTargets;
    
        // Determine targets based on type
        if (type === "device") {
            priceId = this.devicePriceIdTarget.value;
            loaderTargets = this.deviceLoaderTargets; // Loader for device
            priceTargets = this.proratedPriceDeviceTargets; // Price for device
        } else if (type === "storage") {
            priceId = this.storagePriceIdTarget.value;
            loaderTargets = this.storageLoaderTargets; // Loader for storage
            priceTargets = this.proratedPriceStorageTargets; // Price for storage
        }
        else {
            priceId = document.querySelector(`[data-pricing-calculator-target="${type}PriceId"]`).value;

            loaderTargets = document.querySelectorAll(`[data-pricing-calculator-target="${type}Loader"]`);
            priceTargets = document.querySelectorAll(`[data-pricing-calculator-target="proratedPrice${type}"]`);
        }

        const addonData = {
            price_id: priceId,
            quantity: type === "device" ? deviceCount : (type === "storage" ? storageQuantity : 1)
        };
    
        const params = new URLSearchParams({
            addons: JSON.stringify(addonData),
        });
    
        // Show loader and hide price
        loaderTargets.forEach((loader) => loader.classList.remove("hidden"));
        priceTargets.forEach((price) => price.classList.add("hidden"));
    
        fetch(`/settings/generate_addon_invoice?${params}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (priceTargets) {
                    // Hide loader and show updated price
                    loaderTargets.forEach((loader) => loader.classList.add("hidden"));
                    priceTargets.forEach((price) => {
                        price.classList.remove("hidden");
                        price.textContent = `${this.currency}${data.prorated_amount}`;
                    });
                }
            })
            .catch((error) => {
                console.error("Error fetching prorated price:", error);
    
                if (priceTargets) {
                    // Hide loader and show error message
                    loaderTargets.forEach((loader) => loader.classList.add("hidden"));
                    priceTargets.forEach((price) => {
                        price.classList.remove("hidden");
                        price.textContent = "Error!";
                    });
                }
            });
    }

    submitForm(event) {
        
        const form = event.currentTarget.querySelector("form")

        if(form){
            form.submit();
            form.classList.add("hidden");
            let loader = event.currentTarget.querySelector("#button-loader") || event.currentTarget.querySelector("#button-loader-login");
            
            if(loader){ 
                loader.classList.remove("hidden");
            }
        }
      }

}
